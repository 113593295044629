import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [2,5];

export const dictionary = {
		"/(app)": [9,[2],[3]],
		"/(app)/admin": [10,[2,4],[3]],
		"/(app)/admin/organisation": [~11,[2,4],[3]],
		"/(app)/admin/roles": [~12,[2,4],[3]],
		"/(app)/analytics": [13,[2],[3]],
		"/auth/login": [37,[8]],
		"/auth/register": [38,[8]],
		"/auth/verify": [39,[8]],
		"/(app)/calendar": [~14,[2],[3]],
		"/(app)/calendar/[id]": [~15,[2],[3]],
		"/(app)/imageEditor": [16,[2],[3]],
		"/(app)/library": [~17,[2],[3]],
		"/(app)/onboarding": [18,[2],[3]],
		"/(app)/profile": [~19,[2],[3]],
		"/(app)/reports": [~20,[2],[3]],
		"/(app)/scheduler": [21,[2,5],[3]],
		"/(app)/scheduler/[tab]": [~22,[2,5],[3]],
		"/(app)/settings": [23,[2,6],[3,7]],
		"/(app)/settings/billing": [~24,[2,6],[3,7]],
		"/(app)/settings/billing/success": [25,[2,6],[3,7]],
		"/(app)/settings/brand-profile": [~26,[2,6],[3,7]],
		"/(app)/settings/brands": [~27,[2,6],[3,7]],
		"/(app)/settings/image-templates": [~28,[2,6],[3,7]],
		"/(app)/settings/organisation": [~29,[2,6],[3,7]],
		"/(app)/settings/prompt-settings": [~30,[2,6],[3,7]],
		"/(app)/settings/roles": [~31,[2,6],[3,7]],
		"/(app)/settings/social-accounts": [~32,[2,6],[3,7]],
		"/(app)/settings/user": [~33,[2,6],[3,7]],
		"/(app)/workflows": [34,[2],[3]],
		"/(app)/workflows/[workflowId]": [~35,[2],[3]],
		"/(app)/workflows/[workflowId]/[taskId]": [~36,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';